import axios from 'axios';
import {Buffer} from 'buffer';
export const speciallist = [] //mexico
export const bidlist = [] //mexico  
export const eidlist = []
export const allowList = {
    dev: [],
    alpha : [],
    beta: ['yrchen@noodoe.com','emilychen@noodoe.com','justinhuang@noodoe.com','yarinliu@noodoe.com','joyceshen@noodoe.com','sunnsun@noodoe.com','algerlin@ionexenergy.com'].concat(speciallist),
    prod: ['yrchen@noodoe.com','emilychen@noodoe.com','justinhuang@noodoe.com','yarinliu@noodoe.com','joyceshen@noodoe.com','sunnsun@noodoe.com','algerlin@ionexenergy.com']
}

export const session_name = 'browser-ionex-web-data';

export const server = {
    "dev-test": {
        mystique: 'https://mystique-dev.ionexenergy.com',
        nex: 'https://nex-dev-test.ionexenergy.com',
        mamacoco: 'https://mamacoco-dev.ionexenergy.com',
        mystiqueApiKey: Buffer.from(process.env.REACT_APP_DEV_API_KEY , 'base64').toString()
    },
    "dev": {
        mystique: 'https://mystique-dev.ionexenergy.com',
        nex: 'https://nex-dev.ionexenergy.com',
        mystiqueApiKey: Buffer.from(process.env.REACT_APP_DEV_API_KEY , 'base64').toString(),
        mamacocoAP: Buffer.from(process.env.REACT_APP_MAMACOCO_DEV_ADMIN_AP , 'base64').toString()
    },
    "alpha": {
        mystique: 'https://mystique-alpha.ionexenergy.com',
        nex: 'https://nex-alpha.ionexenergy.com',
        mamacoco: 'https://mamacoco-alpha.ionexenergy.com',
        mystiqueApiKey: Buffer.from(process.env.REACT_APP_DEV_API_KEY , 'base64').toString(),
        mamacocoAP: Buffer.from(process.env.REACT_APP_MAMACOCO_ALPHA_ADMIN_AP , 'base64').toString()
    },
    "beta": {
        mystique: 'https://mystique-beta.ionexenergy.com',
        nex: 'https://nex-api-beta.ionexenergy.com',
        mamacoco: 'https://mamacoco-mx-beta.ionexenergy.com',
        mystiqueApiKey: Buffer.from(process.env.REACT_APP_BETA_API_KEY , 'base64').toString(),
        mamacocoAP: Buffer.from(process.env.REACT_APP_MAMACOCO_BETA_ADMIN_AP , 'base64').toString()
    },
    "prod": {
        mystique: 'https://mystique.ionexenergy.com',
        nex: 'https://nex-api.ionexenergy.com',
        mamacoco: 'https://mamacoco-mx-prod.ionexenergy.com',
        mystiqueApiKey: Buffer.from(process.env.REACT_APP_PROD_API_KEY , 'base64').toString(),
        mamacocoAP: Buffer.from(process.env.REACT_APP_MAMACOCO_PROD_ADMIN_AP , 'base64').toString()
    },
    "backend": {
        url : process.env.REACT_APP_BACKEND_URL
    },
    "fluentd": {
      
        url: 'https://fluentd-s3.ionexenergy.com',
       
    },
}



export async function axiosRequest(data) {
    let res;
    if (data.method === 'get') {
        res = await axios.get(data.url, data.config)
        return res.data
    } else if (data.method === 'post'  ) {
        res = await axios.post(data.url, data.postData, data.config)
        return res.data
    } else if (data.method === 'put' ) {
        res = await axios.put(data.url, data.postData, data.config)
        return res.data
    } else if (data.method === 'delete' ) {
        res = await axios.delete(data.url, data.config)
        return res.data
    } else if (data.method === 'patch' ) {
        res = await axios.patch(data.url, data.postData, data.config)
        return res.data
    }
    
}



export async function fluentd_s3(jsondata) {
    const data = {
        method: 'post',
        url : `${server['fluentd'].url}/noodoe.app.iondex.admin.debug`,
        postData : jsondata,
        config : {
          headers: {
              'Content-Type': 'application/json',
          }
        }
    }
    return await axiosRequest(data)
} 

export const firebaseAuth = {
    account: Buffer.from(process.env.REACT_APP_AUTH_ACCOUNT , 'base64').toString().replace(/\n/g, ''),
    password: Buffer.from(process.env.REACT_APP_AUTH_PASSWORD , 'base64').toString().replace(/\n/g, ''),
}


export function checkSession() {
    const cachedHits = sessionStorage.getItem(session_name);
    let session = false;
    // console.log(cachedHits)
    if (cachedHits) {
        session = true
    }
    return [session, JSON.parse(cachedHits)]
}


const prodConfig =  JSON.parse(Buffer.from(process.env.REACT_APP_PROD_CONFIG , 'base64').toString())
const devConfig =  JSON.parse(Buffer.from(process.env.REACT_APP_DEV_CONFIG , 'base64').toString()) 
const alphaConfig =  JSON.parse(Buffer.from(process.env.REACT_APP_ALPHA_CONFIG , 'base64').toString())
const betaConfig =  JSON.parse(Buffer.from(process.env.REACT_APP_BETA_CONFIG , 'base64').toString())

export const firebaseConfigs = {
    'dev': devConfig,
    'alpha': alphaConfig,
    'beta': betaConfig,
    'prod': prodConfig
}

// window.Buffer = window.Buffer || require("buffer").Buffer;